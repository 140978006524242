<template>
  <v-row>
    <v-col cols="12">
      <v-form ref="form">
        <base-card>
          <v-card-title>Registrar Clientes FE</v-card-title>
          <v-card-text>
            <v-row>
              <v-col
                cols="12"
                sm="6"
              >
                <v-text-field
                  ref="NombreCompania"
                  v-model="dataClient.NombreCompania"
                  label="Cliente"
                  prepend-inner-icon="mdi-account-tie"
                  clearable
                  :rules="formRules"
                  :error-messages="errorMessages"
                  required
                />
              </v-col>

              <v-col
                cols="12"
                sm="4"
              >
                <v-select
                  ref="Estado"
                  v-model="dataClient.Estado"
                  :items="typeEnvironment"
                  item-text="textEnvironment"
                  item-value="valueEnvironment"
                  label="Ambiente"
                  prepend-inner-icon="mdi-sitemap"
                  :rules="formRules"
                  :error-messages="errorMessages"
                  required
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="12"
                sm="4"
              >
                <v-text-field
                  ref="URLImagen"
                  v-model="dataClient.URLImagen"
                  label="URL Imagen Logo"
                  prepend-inner-icon="mdi-file-image"
                  clearable
                  :rules="formRules"
                  :error-messages="errorMessages"
                />
                Vista previa:
                <div
                  v-if="dataClient.URLImagen"
                  id="preview"
                >
                  <img
                    style="width:60%"
                    :src="dataClient.URLImagen"
                  >
                </div>
              </v-col>

              <v-col
                cols="12"
                sm="4"
              >
                <v-btn
                  class="ma-2"
                  style="background-color:rgb(204 33 40);color:white !important"
                  @click="validateClient"
                >
                  Registrar
                </v-btn>
              </v-col>
            </v-row>
            <v-row>
              <v-alert
                v-show="successAlert"
                type="success"
                text
              >
                {{ msgSuccessAlert }}
              </v-alert>
            </v-row>
          </v-card-text>
        </base-card>
      </v-form>
    </v-col>
  </v-row>
</template>

<script>
  import { listEnvironment } from '@/data/listEnvironment'

  export default {
    name: 'RegistrarClients',

    data () {
      return {
        permisosRoles: ['administrador'],
        dataClient: {},
        URLImagen: '',
        NombreCompania: '',
        Estado: '',
        typeEnvironment: listEnvironment,
        errorMessages: '',
        successAlert: false,
        msgSuccessAlert: 'Cliente registrado exitosamente',
        formRules: [
          (v) => !!v || 'Este campo es requerido',
        ],
      }
    },
    computed: {

      form () {
        return {
          NombreCompania: this.dataClient.NombreCompania,
          URLImagen: this.dataClient.URLImagen,
          Estado: this.dataClient.Estado,
        }
      },
      statusResponseClient () {
        return this.$store.state.clients.statusResponse
      },

    },
    watch:
      {
        statusResponseClient () {
          if (this.statusResponseClient === true) {
            this.generateAlertSuccess()
            this.$store.dispatch('clients/setStatusResponse', false)
          }
        },
      },
    methods: {

      generateAlertSuccess () {
        this.successAlert = true
        this.$store.dispatch('clients/getListClients')
        this.clearForm()
        setTimeout(() => {
          this.successAlert = false
        }, 5000)
      },
      clearForm () {
        this.$refs.form.reset()
      },
      validateClient () {
        if (this.$refs.form.validate()) {
          this.sendClient()
        }
      },
      sendClient () {
        this.$store.dispatch('clients/insertClients', {
          ...this.dataClient,
        })
      },
    },
  }
</script>
